/* src/index.css or src/App.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


.react-flow__handle {
  width: 8px;
  height: 8px;
  background-color: #555;
}

.react-flow__handle-left {
  left: -4px;
}

.react-flow__handle-right {
  right: -4px;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
}

.close-button {
  background: #ff5f5f;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

body {
  /* Apply a gradient from slate-50 to slate-100 and slate-200 */
  background: linear-gradient(
    135deg,
    #f1f5f9,
    #e2e8f0,
    #cbd5e1
  ); /* Slate colors */
  background-size: 400% 400%; /* Large size for the background to allow animation */
  animation: gradientAnimation 10s ease infinite; /* Animate the gradient for a flowing effect */

  position: relative;
  z-index: -1; /* Ensures the gradient background stays behind other content */

  /* Keyframes for gradient animation */
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}




@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes loading-bar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 0.6s ease-out forwards;
}

.animation-delay-100 {
  animation-delay: 100ms;
}

.animation-delay-200 {
  animation-delay: 200ms;
}

.animate-loading-bar {
  animation: loading-bar 2s linear infinite;
}